import React, { useCallback, useEffect, useState } from 'react'
import { Button, NavbarBrand, Container, Navbar, Row, Col, Card, Table, Modal, Spinner } from 'react-bootstrap'
import {
    BoxArrowRight,
    BuildingsFill, CreditCardFill,
    PeopleFill,
    PersonBadgeFill,
    PersonFillGear,
    Speedometer2, WalletFill
} from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'



const AdminDashboard = () => {

    document.title = 'Admin Dashboard';
    const navigate = useNavigate();
    const [data, setData] = useState({ members: 0, clubs: 0, officers: 0, cabinets: 0, amount: 0, limit: 0 });
    const [logs, setLogs] = useState([])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const fetchData = useCallback(async () => {
        try {
            const response = await fetch('/v1/dashboard', {
                method: 'GET',
                headers: { 'Authorization': localStorage.getItem('_adminAuth') }
            });
            if (response.ok) {
                const dataResponse = await response.json();
                setData({
                    members: dataResponse[0][0].members,
                    clubs: dataResponse[1][0].clubs,
                    officers: dataResponse[2][0].officers,
                    cabinets: dataResponse[3][0].cabinets,
                    amount: 0,
                    limit: 0,
                });
                setLogs([]);
            } else {
                // Handle response errors here
                console.error('Failed to fetch data');
            }
        } catch (error) {
            // Handle network errors here
            console.error('Error fetching data:', error);
        }
    }, []);

    useEffect(() => {
        fetchData().then(r => { });
    }, [fetchData]);

    const logout = () => {
        localStorage.clear()
        navigate('/')
    }

    return (
        <>
            <Navbar expand="lg" bg="dark" className='border-bottom sticky-top' data-bs-theme="dark">
                <Container fluid>
                    <NavbarBrand>
                        <Speedometer2 /> Dashboard
                    </NavbarBrand>
                    <Button variant='danger' onClick={logout}><BoxArrowRight /> Logout</Button>
                </Container>
            </Navbar>

            <Container fluid>
                <Row>
                    <Col lg={3} md={6} sm={12}>
                        <Card className='mt-2 mb-2' bg={'primary'}>
                            <Card.Header>
                                <Card.Title>
                                    MEMBERS
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <h1>{data.members}</h1>
                                </div>
                                <div>
                                    <PeopleFill size={96} />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <Card className='mt-2 mb-2' bg={'success'}>
                            <Card.Header>
                                <Card.Title>
                                    CLUBS
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <h1>{data.clubs}</h1>
                                </div>
                                <div>
                                    <BuildingsFill size={96} />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <Card className='mt-2 mb-2' bg={'secondary'}>
                            <Card.Header>
                                <Card.Title>
                                    OFFICERS
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <h1>{data.officers}</h1>
                                </div>
                                <div>
                                    <PersonFillGear size={96} />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <Card className='mt-2 mb-2' bg={'warning'} data-bs-theme="light">
                            <Card.Header>
                                <Card.Title>
                                    CABINETS
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <h1>{data.cabinets}</h1>
                                </div>
                                <div>
                                    <PersonBadgeFill size={96} />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* <Container fluid>
                <Row>
                    <Col lg={3} md={12}>
                        <Card className='mt-2 mb-2' bg={'secondary'}>
                            <Card.Header>
                                <Card.Title>
                                    SMS BILL
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className='d-flex justify-content-between'>
                                <div>
                                    <h1>₹ {data.amount}</h1>
                                    <h5>Limit {data.limit}</h5>
                                </div>
                                <div>
                                    <WalletFill size={96} />
                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <Button variant={'dark'} className={'w-100'} disabled={data.amount < 10}><CreditCardFill /> Pay now</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={9} md={12}>
                        <Table striped bordered hover className="mt-2 rounded-2">
                            <thead>
                                <tr>
                                    <th>Phone no</th>
                                    <th>Code</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    logs.map((log, index) => (
                                        <tr key={index}>
                                            <td>{log.phone}</td>
                                            <td>{log.code}</td>
                                            <td>{log.status}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container> */}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Checkout in progress</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center">
                        <Spinner animation="grow" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdminDashboard