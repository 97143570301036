import React, { useEffect, useState } from 'react'
import { Alert, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'

const AdminProtected = ({ Component }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const [inputUsername, setInputUsername] = useState("");
    const [inputPassword, setInputPassword] = useState("");

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        


        const verifier = await fetch('/v1/auth/admin', { method: 'POST', 
            body: JSON.stringify({username: inputUsername, password: inputPassword}), 
            headers: { 'Content-Type': 'application/json' } })
        if (verifier.ok) {
            const data = await verifier.json()
            localStorage.setItem("_adminAuth", data.token)
            window.location.href = "/admin"
            setLoading(false);
        }else{
            setShow(true);
            setLoading(false);
        }
    };




    useEffect(() => {
        const checkLogin = () => {
            const login = localStorage.getItem("_adminAuth");
            if (login) {
                setIsAuthenticated(true);
            } else {
                // window.location.href = `https://sso.messant.in?callback=${window.location.protocol}//${window.location.hostname}:${window.location.port}/adminlogin&app_id=321e`
            }
        };

        checkLogin();
    }, []);

    if (!isAuthenticated) {
        return (
            <div
                className="sign-in__wrapper"
            >
                {/* Overlay */}
                
                {/* Form */}
                <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
                    {/* Header */}
                    <img
                        className="img-thumbnail mx-auto d-block mb-2"
                        src="/lion.png"
                        alt="logo"
                    />
                    <div className="h4 mb-2 text-center text-dark">Sign In</div>
                    {/* ALert */}
                    {show ? (
                        <Alert
                            className="mb-2"
                            variant="danger"
                            onClose={() => setShow(false)}
                            dismissible
                        >
                            Incorrect username or password.
                        </Alert>
                    ) : (
                        <div />
                    )}
                    <Form.Group className="mb-2" controlId="username">
                        <Form.Label className='text-dark'>Username</Form.Label>
                        <Form.Control
                            type="text"
                            value={inputUsername}
                            placeholder="Username"
                            onChange={(e) => setInputUsername(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="password">
                        <Form.Label className='text-dark'>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={inputPassword}
                            placeholder="Password"
                            onChange={(e) => setInputPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    
                    <div className='mt-4'>
                    {!loading ? (
                        <Button className="w-100" variant="primary" type="submit">
                            Log In
                        </Button>
                    ) : (
                        <Button className="w-100" variant="primary" type="submit" disabled>
                            Logging In...
                        </Button>
                    )}
                    </div>
                </Form>
                {/* Footer */}
                <div className="w-100 mb-2 position-absolute bottom-0 start-50 translate-middle-x text-white text-center">
                    Messant Technologies | &copy;2025
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <Component />
            </div>
        )
    }
}

export default AdminProtected